<template>
  <div>
    <GoBackHeader defaultHref='/check-in' />

    <ion-content fullscreen padding scroll-y="false">
      <div v-if="!$apollo.loading">
        <ion-text color="primary">
          <h1>{{ getTicketForEvent.event.title }}</h1>
        </ion-text>
        <h2>{{ getTicketForEvent.user.first_name }} {{ getTicketForEvent.user.last_name }}</h2>

        <ion-slides pager="true" class="py-10">
          <ion-slide v-for="qrcode in getTicketForEvent.qr_codes" :key="qrcode.id">
            <div>
              <qrcode-vue :value="qrcode.qr_code" size="200" level="H" class="mt-2 ml-2 flex justify-center" />
            </div>
          </ion-slide>
        </ion-slides>
      </div>
    </ion-content>
  </div>
</template>

<script>
    import { GET_TICKETS_FOR_ORDER } from "@/graphql/queries";
    import QrcodeVue from 'qrcode.vue'
    import GoBackHeader from "@/components/navigation/GoBackHeader";

    export default {
      components: {
        QrcodeVue,
        GoBackHeader
      },
      apollo: {
        getTicketForEvent: {
          query: GET_TICKETS_FOR_ORDER,
          variables() {
            return {
              id: this.$route.params.id
            }
          }
        }
      }
    }
</script>

<style scoped>
    ion-list.events {
        background: #fff;
    }

    ion-list.events ion-item {
        --background: var(--ion-color-white);
    }

    ion-toolbar {
        --background: var(--ion-color-tertiary)
    }
</style>
